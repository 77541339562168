import revive_payload_client_MCbDrXiuXI from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._5q7e5oc4sn3juzwxwoappzjdqq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EVae7R8rA5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._5q7e5oc4sn3juzwxwoappzjdqq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_taZWOfeX1n from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._5q7e5oc4sn3juzwxwoappzjdqq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_ochTmB7uqX from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_9XBlKNDmFf from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._5q7e5oc4sn3juzwxwoappzjdqq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QSlz5iDS4N from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._5q7e5oc4sn3juzwxwoappzjdqq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bUTKRWcmPU from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._5q7e5oc4sn3juzwxwoappzjdqq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bRviMaVMNu from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._5q7e5oc4sn3juzwxwoappzjdqq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hjvCkaMotf from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_TofsOPjs8m from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._5q7e5oc4sn3juzwxwoappzjdqq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import slideovers_HBGerWxjJR from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_jwt-decode@3.1.2_magicast@0.3.5_rollup@4.24.0_sortablejs@1.14.0_ts-node@10.9._n5djuvm6o6vkex7mqwtdyqmm7y/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_l1ud8geF2V from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_jwt-decode@3.1.2_magicast@0.3.5_rollup@4.24.0_sortablejs@1.14.0_ts-node@10.9._n5djuvm6o6vkex7mqwtdyqmm7y/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ZtVPtUfbaq from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.6_jwt-decode@3.1.2_magicast@0.3.5_rollup@4.24.0_sortablejs@1.14.0_ts-node@10.9._n5djuvm6o6vkex7mqwtdyqmm7y/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_xyy6tkAUk1 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_RplIaJt35h from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.5.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@20.16.11_sass@1.79.4_ter_j3vgaglgyabddnyhtyslxiv4yq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import switch_locale_path_ssr_BJANLwjFtk from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_aEDZrhq82A from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import auth_redirect_5EDtur7iPR from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import sentry_client_SmckuZfb0b from "/opt/build/repo/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._5kmfijf3a67fshzokdfwpzyh4y/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import chartjs_client_N7GULTnRpX from "/opt/build/repo/plugins/chartjs.client.ts";
import vue_draggable_client_CtEMRI3Db3 from "/opt/build/repo/plugins/vue-draggable.client.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_MCbDrXiuXI,
  unhead_EVae7R8rA5,
  router_taZWOfeX1n,
  supabase_client_ochTmB7uqX,
  payload_client_9XBlKNDmFf,
  navigation_repaint_client_QSlz5iDS4N,
  check_outdated_build_client_bUTKRWcmPU,
  chunk_reload_client_bRviMaVMNu,
  plugin_vue3_hjvCkaMotf,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TofsOPjs8m,
  formkitPlugin_pZqjah0RUG,
  slideovers_HBGerWxjJR,
  modals_l1ud8geF2V,
  colors_ZtVPtUfbaq,
  plugin_client_xyy6tkAUk1,
  plugin_RplIaJt35h,
  switch_locale_path_ssr_BJANLwjFtk,
  i18n_aEDZrhq82A,
  auth_redirect_5EDtur7iPR,
  sentry_client_SmckuZfb0b,
  sentry_client_config_o34nk2sJbg,
  chartjs_client_N7GULTnRpX,
  vue_draggable_client_CtEMRI3Db3,
  vue_query_wrmMkNpEpe
]